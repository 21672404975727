import axios from "axios";
import Toast from "../Component/toast-alert";

const baseURL = process.env.REACT_APP_API_URL;

export default axios.create({
  baseURL
});

const axiosPrivate = axios.create({
  baseURL,
  headers: {
    "Content-Type": "application/json"
  }
});

const onResponse = response => {
  return response;
};

const onResponseError = async error => {
  const originalRequest = error.config;
  if (error.response?.status === 401 && !originalRequest._retry) {
    originalRequest._retry = true;

    const refreshToken = JSON.parse(localStorage.getItem("refreshToken"));

    try {
      const response = await axios.post(`${baseURL}/auth/refreshToken`, {
        refreshToken
      });
      axiosPrivate.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${response.data.data.accessToken}`;
      return axiosPrivate.request(originalRequest);
    } catch (err) {
      localStorage.clear();
      window.location.href = "/login";
    }
  } else if (error.response?.status === 500) {
    Toast.fire({
      icon: "error",
      title: "Something went wrong, please try again"
    });
    return Promise.reject(error);
  }
  return Promise.reject(error);
};

axiosPrivate.interceptors.response.use(onResponse, onResponseError);

const axiosFormData = axios.create({
  baseURL,
  headers: {
    "Content-Type": "multipart/form-data"
  }
});

axiosFormData.interceptors.response.use(onResponse, onResponseError);

const axiosBlob = axios.create({
  baseURL,
  responseType: "blob",
  headers: {
    "Content-Type": "application/json"
  }
});

axiosBlob.interceptors.response.use(onResponse, onResponseError);

export { axiosPrivate, axiosBlob, axiosFormData, baseURL };
