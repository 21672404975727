/**
 * Array of font families.
 * - NOTE: This will throw an error in the webfont loader if the font family is not available in Google Fonts.
 * @type {string[]}
 */
const fontFamilies = [
  "Open Sans",
  "Roboto",
  "Lemon",
  "Sofia",
  "Work Sans",
  "Spectral",
  "Tilt Prism",
  "Shantell Sans",
  "Ubuntu",
  "Roboto",
  "Playfair Display",
  "Abril Fatface",
  "Cinzel",
  "Merriweather",
  "Hammersmith One",
  "Montaga",
  "Inter",
  "Courgette",
  "Shadows Into Light",
  "Tangerine",
  "Dancing Script",
  "Pacifico",
  "Lobster Two",
  "Marck Script",
  "Amatic SC",
  "Indie Flower"
];

export default fontFamilies;
