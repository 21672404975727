import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import { IconButton } from "@mui/material";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { FiEyeOff } from "react-icons/fi";
import { FcGoogle } from "react-icons/fc";
import styles from "./login.module.scss";
import Logo from "../../assets/icons/certgo-logo.svg";
import { useLogin } from "../../mutations/auth/login";
import { useGetGoogleAuthUrl } from "../../mutations/auth/google";
import { NewButton } from "../../Component";
import Banner from "../../assets/images/auth-banner.png";
import FormField from "../../Component/form-field";

const validationSchema = Yup.object().shape({
  email: Yup.string().email("Email is not valid").required("Email is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required and must be at least 8 characters"),
  rememberMe: Yup.boolean().optional()
});

const Login = () => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const [showPassword, setShowPassword] = useState(false);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const login = useLogin();
  const getGoogleAuthUrl = useGetGoogleAuthUrl();

  useEffect(() => {
    (async () => {
      if (params.code) {
        const data = await login.mutateAsync({
          code: params.code
        });

        if (data) {
          navigate(state?.from ? state.from : "/dashboard");
        }
      }
    })();
  }, []);

  return (
    <div id={styles["login"]}>
      <div className={styles["authContainer"]}>
        <div className={styles["left-pane"]}>
          <div className={styles["brand-logo"]}>
            <img src={Logo} alt="certgo-logo" />
          </div>
          <div className={styles["formDiv"]}>
            <Formik
              initialValues={{
                email: "",
                password: "",
                rememberMe: ""
              }}
              validationSchema={validationSchema}
              validateOnBlur={false}
              onSubmit={async values => {
                const data = await login.mutateAsync({
                  email: values.email,
                  password: values.password
                });

                if (data) {
                  navigate(state?.from ? state.from : "/dashboard");
                }
              }}
            >
              {({ handleSubmit, values, setValues, isSubmitting }) => (
                <form onSubmit={handleSubmit}>
                  <div id={styles["heading"]}>Welcome back</div>
                  <small id={styles["startGenerating"]}>
                    Continue generating certificates by logging in to your
                    Certgo account
                  </small>
                  <NewButton
                    variant="outlined"
                    id={styles["google-login-btn"]}
                    onClick={async () => {
                      const data = await getGoogleAuthUrl.mutateAsync("login");
                      if (data) {
                        window.location.href = data.urlAuth;
                      }
                    }}
                    loading={getGoogleAuthUrl.isLoading}
                  >
                    <FcGoogle />
                    Login using Google
                  </NewButton>
                  <div id={styles["hrLine"]}>
                    <span id={styles["or"]}>or</span>
                  </div>
                  <FormField
                    id={styles["login-email"]}
                    label="Email"
                    required
                    placeholder="Enter your email address"
                    name="email"
                    type="email"
                    data-testid="login-email"
                  />
                  <FormField
                    id={styles["login-password"]}
                    label="Password"
                    required
                    placeholder="Enter password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    data-testid="login-password"
                    endAdornment={
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(prev => !prev)}
                        edge="end"
                      >
                        {showPassword ? (
                          <FiEyeOff size={20} />
                        ) : (
                          <MdOutlineRemoveRedEye size={20} />
                        )}
                      </IconButton>
                    }
                  />
                  <div className={styles["forgotPwd"]}>
                    <Link to="/resetpassword" className={styles["forgotPwd"]}>
                      Forgot password?
                    </Link>
                  </div>
                  <div id={styles["checkTerms"]}>
                    <input
                      type="checkbox"
                      id={styles["acceptTerms"]}
                      checked={values.rememberMe}
                      onChange={e => {
                        setValues(prev => ({
                          ...prev,
                          rememberMe: e.target.checked
                        }));
                      }}
                      name="acceptTerms"
                    />
                    <label id="labels" htmlFor="acceptTerms">
                      Remember me
                    </label>
                  </div>
                  <NewButton
                    type="submit"
                    id={styles["login-btn"]}
                    loading={isSubmitting}
                  >
                    <p>Login</p>
                  </NewButton>
                </form>
              )}
            </Formik>
            <p className={styles["haveAccount"]}>
              Don’t have a Certgo account?{" "}
              <Link to="/signup" id={styles["coloredTerms"]}>
                Sign up
              </Link>
            </p>
          </div>
        </div>
        <div className={styles["right-pane"]}>
          <img className={styles["cert_img"]} alt="" src={Banner} />
        </div>
      </div>
    </div>
  );
};

export default Login;
